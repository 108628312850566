import React, { useEffect, useState, useContext } from "react";
import { VStack, HStack, Select, Flex } from "@chakra-ui/react";
import Card from "../components/Home/Card";
import { axiosInstance } from "../Axios";
import { CityContext } from "../context/CityContext";

const Home = () => {
  const [city, setCity] = useState("");
  const [dateRange, setDateRange] = useState(""); 
  const [data, setData] = useState({
    totalCustomers: 0,
    totalMerchants: 0,
    placedOrdersCount: 0,
    netRevenue: 0,
    profit: 0,
    netSettlement: 0,
  });
  const { cities } = useContext(CityContext);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axiosInstance.get("admin/home", {
          params: { city, dateRange },
        });
        setData(response.data);
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchCustomers();
  }, [city, dateRange]);

  return (
    <VStack p={10} w="80%" alignItems={"start"} gap={8}>
      <HStack>
        <Select
          boxShadow={"0px 2px 6px rgba(0,0,0,0.25)"}
          border={"none"}
          w="auto"
          value={dateRange}
          placeholder="Select Time"
          fontWeight={"medium"}
          fontFamily={"Barlow"}
          bg={"#FFF"}
          onChange={(e) => setDateRange(e.target.value)}
        >
          <option value="today">Today</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
        </Select>
        <Select
          boxShadow={"0px 2px 6px rgba(0,0,0,0.25)"}
          border={"none"}
          w="auto"
          placeholder="City"
          fontWeight={"medium"}
          fontFamily={"Barlow"}
          onChange={(e) => setCity(e.target.value)}
          bg={"#FFF"}
        >
          {cities.length > 0 ? (
            cities.map((city) => (
              <option key={city.cityId} value={city.cityName}>
                {city.cityName}
              </option>
            ))
          ) : (
            <option disabled>Loading cities...</option>
          )}
        </Select>
      </HStack>
      <Flex w="full" flexWrap={"wrap"} gap={10}>
        <Card
          img={"./images/home-customer.svg"}
          figure={data.totalCustomers}
          desc={"Customers Onboard"}
        />
        <Card
          img={"./images/home-file.svg"}
          figure={data.totalMerchants}
          desc={"Merchants Onboard"}
        />
        <Card
          img={"./images/home-shopping-bag.svg"}
          figure={data.placedOrdersCount}
          desc={"Orders Placed"}
        />
        <Card
          img={"./images/home-bill.svg"}
          figure={Math.floor(data.netRevenue)}
          desc={"Net Revenue"}
        />
        <Card
          img={"./images/home-bill.svg"}
          figure={Math.floor(data.profit)}
          desc={"Net Profit"}
        />
        <Card
          img={"./images/home-price-tag.svg"}
          figure={data.netSettlement}
          desc={"Net Settlement"}
        />
      </Flex>
    </VStack>
  );
};

export default Home;
