import { Select, VStack } from '@chakra-ui/react';
import React, { useEffect, useState, useContext } from 'react';
import AllCustomersTable from '../components/AllCustomers/AllCustomersTable';
import { axiosInstance } from '../Axios';
import { CityContext } from '../context/CityContext';

const Customers = () => {
    const [city, setCity] = useState("");
    const [allCustomer, setAllCustomers] = useState([])
    const { cities } = useContext(CityContext);

    useEffect(() => {
        const fetchAllCustomers = async () => {
            try {
                const response = await axiosInstance.get("admin/getAllCustomers", {
                    params: { city },
                });
                setAllCustomers(response.data);
            } catch (err) {
                console.error(err.message);
            }
        };

        fetchAllCustomers();
    }, [city]);

    return (
        <VStack
            bg={"#FFF"}
            h="full"
            m={10}
            borderRadius={"20px"}
            alignItems={"start"}
            p={10}
            w="1038px"
        >
            <Select
                boxShadow={"0px 2px 6px rgba(0,0,0,0.25)"}
                border={"none"}
                w="auto"
                placeholder="City"
                fontWeight={"medium"}
                fontFamily={"Barlow"}
                onChange={(e) => setCity(e.target.value)}
            >
                {cities.length > 0 ? (
                    cities.map((city) => (
                        <option key={city.cityId} value={city.cityName}>
                            {city.cityName}
                        </option>
                    ))
                ) : (
                    <option disabled>Loading cities...</option>
                )}
            </Select>
            <AllCustomersTable
                users={allCustomer}
            />
        </VStack>
    );
}

export default Customers;
