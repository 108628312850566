import React, {useContext} from 'react'
import { Navigate, useLocation } from "react-router-dom"
import { AuthContext } from "./context/AuthContext"

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);
    let location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }
    else return children
};

export default ProtectedRoute;