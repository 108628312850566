import { Tr, Td, Text, Box, useDisclosure } from "@chakra-ui/react";
import AccountApprovalModal from "./AccountApprovalModal";

const AccountTableRow = ({ user, users, setUsers, index }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const tdStyle = {
    fontFamily: "Roboto",
    color: "black",
  };

  return (
    <Tr key={user.id}>
      <Td style={tdStyle}>{index + 1}</Td>
      <Td style={tdStyle} display={"flex"} alignItems={"center"}>
        <Text>{user.name}</Text>
        {user?.isAvailable && (
          <Box bgColor={"green"} w={2} h={2} borderRadius={"50%"} ml={1}></Box>
        )}
      </Td>
      <Td style={tdStyle}>{user.phoneNumber}</Td>
      <Td style={tdStyle}>
        <Text
          cursor={"pointer"}
          color={"scarlet"}
          textDecor={"underline"}
          onClick={() => {
            onOpen();
          }}
        >
          View Details
        </Text>
      </Td>
      <AccountApprovalModal
        user={user}
        users={users}
        setUsers={setUsers}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Tr>
  );
};

export default AccountTableRow;
