import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/react";
import { axiosInstance } from "../../Axios";
import PastOrders from "../Order/PastOrders";

const AllCustomersModal = ({ user, isOpen, onClose }) => {
  const [orders, setOrders] = useState([]);

  const getHistoryOfOrders = async () => {
    try {
      const response = await axiosInstance.get(`admin/orderHistory/${user.userId}`);
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching order history", error);
    }
  };

  useEffect(() => {
    if (isOpen && user?.userId) {
      getHistoryOfOrders();
    }
  }, [user, isOpen]);

  const textStyle = {
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "27px",
  };

  const textValue = {
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "27px",
  };

  const hStackStyle = {
    width: "100%",
    justifyContent: "space-between",
  };

  // Date formatting function
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Modal size={""} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={"1038px"} height={"827px"} borderRadius={"20px"}>
        <ModalCloseButton />
        <ModalBody p={10} w="full">
          <VStack gap={16} w="full">
            <HStack alignItems={"start"} w="full" h="183px" gap={"52px"}>
              <VStack>
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>Phone Number:</Text>
                  <Text style={textValue}>{user?.phoneNumber}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>Name:</Text>
                  <Text style={textValue}>{user?.name}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>Email ID:</Text>
                  <Text style={textValue}>{user?.email}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>Date of Birth:</Text>
                  <Text style={textValue}>
                    {user?.dob ? formatDate(user.dob) : 'N/A'}
                  </Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>Gender:</Text>
                  <Text style={textValue}>{user?.gender}</Text>
                </HStack>
              </VStack>
            </HStack>
            <PastOrders orders={orders} />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AllCustomersModal;
