import { createContext, useState, useEffect } from "react";
import { axiosInstance } from "../Axios";

export const CityContext = createContext();

export const CityProvider = ({ children }) => { 
    const [cities, setCities] = useState([]);

    const fetchCities = async () => {
        try {
            const response = await axiosInstance.get("admin/city");
            setCities(response.data.list);
        } catch (err) {
            console.error("Error fetching cities:", err.message);
        }
    };

    useEffect(() => {
        fetchCities();
    }, []);

    return (
        <CityContext.Provider value={{ cities }}>
            {children}
        </CityContext.Provider>
    );
}