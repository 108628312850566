import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated'));
    const [token, setToken] = useState(localStorage.getItem('jwtToken'));

    const login = (token) => {
        localStorage.setItem('jwtToken', token);
        localStorage.setItem('isAuthenticated', true);
        setIsAuthenticated(true);
        setToken(token);
    };

    const logout = () => {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('isAuthenticated');
        setIsAuthenticated(false);
        setToken('');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
